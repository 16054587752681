import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {EntitesService} from './entites.service';
import {AuthService} from './auth.service';
import {Constantes} from '../constantes';

@Injectable({
    providedIn: 'root'
})
export class TransactionsService {
    currentEntite;
    constructor(private http: HttpClient) {}
    // Filtre d'envoi
    filterEnvoi(values) {
        let urlpath = '?true=1';
        if (values.filtre && values.filtre.length > 0) {
            values.filtre.forEach((item: any) => {
                if (item.filtre == 'codeOperateur') {
                    urlpath += '&operateur.codeOperateur=' + item.id;
                } else if (item.filtre == 'numTransaction') {
                    urlpath += '&numTransaction=' + item.id;
                } else if (item.filtre == 'operation') {
                    urlpath += '&status=' + item.id;
                }
            });
        } else {
            urlpath = '';
        }
        return urlpath;
    }
    filterTransaction(values) {
        let urlpath = '?_page=' + values.page;
        if (values.filtre && values.filtre.length > 0) {
            values.filtre.forEach((item: any) => {
                if (item.filtre == 'service') {
                    urlpath += '&service.code=' + item.id;
                } else if (item.filtre == 'operateur') {
                    urlpath += '&partenaire.code=' + item.id;
                } else if (item.filtre == 'numTransac') {
                    urlpath += '&numTransaction=' + item.id;
                } else if (item.filtre == 'operation') {
                    urlpath += '&operation=' + item.id;
                }
            });

        } else {
            urlpath = '';
        }
        return urlpath;
    }

    filterHistoEnvoi(values) {
        let urlpath = '?_page=' + values.page;
        if (values.filtre && values.filtre.length > 0) {
            values.filtre.forEach((item: any) => {
                if (item.filtre == 'code') {
                    urlpath += '&code=' + item.id;
                } else if (item.filtre == 'datedebut') {
                    urlpath += '&dateCreation%5Bafter%5D=' + item.id;
                } else if (item.filtre == 'datefin') {
                    urlpath += '&dateCreation%5Bbefore%5D=' + item.id;
                } else if (item.filtre == 'nomDest') {
                    urlpath += '&destinataire.prenom=' + item.id;
                } else if (item.filtre == 'telDest') {
                    urlpath += '&destinataire.telephone=' + item.id;
                } else if (item.filtre == 'montant') {
                    urlpath += '&montantNet=' + item.id;
                }
            });
        } else {
            urlpath = '';
        }
        return urlpath;
    }

    filterexport(values) {
        let urlpath = '?true=true';
        if (values.filtre && values.filtre.length > 0) {
            values.filtre.forEach((item: any) => {
                if (item.filtre == 'code') {
                    urlpath += '&code=' + item.id;
                } else if (item.filtre == 'datedebut') {
                    urlpath += '&dateCreation%5Bafter%5D=' + item.id;
                } else if (item.filtre == 'datefin') {
                    urlpath += '&dateCreation%5Bbefore%5D=' + item.id;
                } else if (item.filtre == 'nomDest') {
                    urlpath += '&destinataire.prenom=' + item.id;
                } else if (item.filtre == 'telDest') {
                    urlpath += '&destinataire.telephone=' + item.id;
                } else if (item.filtre == 'montant') {
                    urlpath += '&montantNet=' + item.id;
                }
            });
        } else {
            urlpath = '';
        }
        return urlpath;
    }

    getAllTransactions(values) {
        return this.http.get<any>(`${environment.apiReseauUrl}/transactions/entite${this.filterTransaction(values)}`);
    }

    getService() {
        return this.http.get<any>(`${environment.apiReseauUrl}/services`);
    }

    getRecentTransactions(codeCaisse) {
        return this.http.get<any>(`${environment.apiReseauUrl}/transactions/entite/jour?_itemsPerPage=4&_page=1`);
    }

    getoperateur(code) {
        return this.http.get<any>(`${environment.apiAdminUrl}/operateurs?codeEntite=${code}`);
    }

    getbilanCaisse() {
        return this.http.get<any>(`${environment.apiReseauUrl}/transactions/jour/bilan`);
    }

    getTransactionJour() {
        return this.http.get<any>(`${environment.apiReseauUrl}/etatsjournalier/encaissement`);
    }

    //historique Envoi
    transactionEnvoi(data) {
        return this.http.get<any>(environment.apiTransfertUrl + '/transactions/entite' + this.filterEnvoi(data));
    }

    exportTransfert(data, type) {
        return this.http.get(environment.apiTransfertUrl + '/transactions/entite/jour/' + type + this.filterEnvoi(data), {
            observe: 'response',
            responseType: 'blob'
        });
    }

    historiqueEnvoi(values) {
        return this.http.get<any>(`${environment.apiTransfertUrl}/envois${this.filterHistoEnvoi(values)}`);
    }

    historiqueAll(data) {
        return this.http.get<any>(environment.apiTransfertUrl + '/transactions/entite/jour' + this.filterEnvoi(data));
    }

    historiqueRerait(values) {
        return this.http.get<any>(`${environment.apiTransfertUrl}/retraits${this.filterHistoRetrait(values)}`);
    }

    historiqueRemb(values) {
        return this.http.get<any>(`${environment.apiTransfertUrl}/remboursements/demandes${this.filterHistoan(values)}`);
    }

    historiqueAnnule(values) {
        return this.http.get<any>(`${environment.apiTransfertUrl}/annulations/demandes${this.filterHistoan(values)}`);
    }

    payerAnnulation(values) {
        return this.http.put<any>(`${environment.apiTransfertUrl}/annulations/${values.id}/payer`, values);
    }

    payerRem(values) {
        return this.http.put<any>(`${environment.apiTransfertUrl}/remboursements/${values.id}/payer`, values);
    }

    exportTransfertEnvoi(data) {
        return this.http.get(`${environment.apiTransfertUrl}/envois/entite/pdf${this.filterexport(data)}`, {
            observe: 'response',
            responseType: 'blob'
        });
    }

    exportTrsfertEnvoiExcel(data) {
        return this.http.get(`${environment.apiTransfertUrl}/envois/entite/csv${this.filterexport(data)}`, {
            observe: 'response',
            responseType: 'blob'
        });
    }

    exportTrsfertAnPdf(data) {
        return this.http.get(`${environment.apiTransfertUrl}/annulations/demandes/pdf${this.filterexport(data)}`, {
            observe: 'response',
            responseType: 'blob'
        });
    }

    exportTrsfertAnExcel(data) {
        return this.http.get(`${environment.apiTransfertUrl}/annulations/demandes/csv${this.filterexport(data)}`, {
            observe: 'response',
            responseType: 'blob'
        });
    }

    exportTrsfertRemPdf(data) {
        return this.http.get(`${environment.apiTransfertUrl}/remboursements/demandes/pdf${this.filterexport(data)}`, {
            observe: 'response',
            responseType: 'blob'
        });
    }

    exportTrsfertRemExcel(data) {
        return this.http.get(`${environment.apiTransfertUrl}/remboursements/demandes/csv${this.filterexport(data)}`, {
            observe: 'response',
            responseType: 'blob'
        });
    }

    exportTrsfertRetPdf(data) {
        return this.http.get(`${environment.apiTransfertUrl}/retraits/pdf${this.filterexport(data)}`, {
            observe: 'response',
            responseType: 'blob'
        });
    }

    exportTrsfertRetExcel(data) {
        return this.http.get(`${environment.apiTransfertUrl}/retraits/csv${this.filterexport(data)}`, {
            observe: 'response',
            responseType: 'blob'
        });
    }

    EnvoiActive(values) {
        return this.http.put<any>(`${environment.apiTransfertUrl}/envois/activer/${values}`, {});
    }

    filterHistoan(values) {
        let urlpath = '?_page=' + values.page;
        if (values.filtre && values.filtre.length > 0) {
            values.filtre.forEach((item: any) => {
                if (item.filtre == 'code') {
                    urlpath += '&envoi.code=' + item.id;
                } else if (item.filtre == 'datedebut') {
                    urlpath += '&dateCreation%5Bafter%5D=' + item.id;
                } else if (item.filtre == 'datefin') {
                    urlpath += '&dateCreation%5Bbefore%5D=' + item.id;
                } else if (item.filtre == 'nomDest') {
                    urlpath += '&envoi.destinataire.prenom=' + item.id;
                } else if (item.filtre == 'telDest') {
                    urlpath += '&envoi.destinataire.telephone=' + item.id;
                } else if (item.filtre == 'montant') {
                    urlpath += '&envoi.montantNet=' + item.id;
                }
            });

        } else {
            urlpath = '';
        }
        return urlpath;
    }

    filterHistoRetrait(values) {
        let urlpath = '?_page=' + values.page;
        if (values.filtre && values.filtre.length > 0) {
            values.filtre.forEach((item: any) => {
                if (item.filtre == 'code') {
                    urlpath += '&envoi.code=' + item.id;
                } else if (item.filtre == 'datedebut') {
                    urlpath += '&dateCreation%5Bafter%5D=' + item.id;
                } else if (item.filtre == 'datefin') {
                    urlpath += '&dateCreation%5Bbefore%5D=' + item.id;
                } else if (item.filtre == 'nomDest') {
                    urlpath += '&beneficiaire.prenom=' + item.id;
                } else if (item.filtre == 'telDest') {
                    urlpath += '&beneficiaire.telephone=' + item.id;
                } else if (item.filtre == 'montant') {
                    urlpath += '&envoi.montantNet=' + item.id;
                }

            });

        } else {
            urlpath = '';
        }
        return urlpath;
    }

//Load transactions
    getEntiteTransactions(page, query) {
        return this.http.get<any[]>(`${environment.apiReseauUrl}/transactions/entite/jour?_page=${page}${query}`);
    }
    getAllETransactions(page, query) {
        return this.http.get<any[]>(`${environment.apiReseauUrl}/transactions/entite?_page=${page}${query}`);
    }


    filterTransactions(query) {
        return this.http.get<any[]>(`${environment.apiReseauUrl}/transactions/entite${query}`);
    }

    getTransactionBilan() {
        return this.http.get<any[]>(environment.apiReseauUrl + '/transactions/bilan');
    }

    toPdf(query) {
        return this.http.get(`${environment.apiReseauUrl}/transactions/pdf?true=true${query}`, {observe: 'response', responseType: 'blob'});
    }

    toCSV(query) {
        return this.http.get(`${environment.apiReseauUrl}/transactions/csv?true=true${query}`, {observe: 'response', responseType: 'blob'});
    }

    getTransferByTransactionNum(transactionNum) {
        return this.http.get<any[]>(`${environment.apiTransfertUrl}/transactions/?numTransaction=${transactionNum}`);
    }

    getTrsWallet() {
        return this.http.get<any[]>(`${environment.walletApi}/transactions/clients`);
    }

    getBordereau(numTransaction) {
        return this.http.get(`${environment.apiReseauUrl}/transactions/bordereau/pdf?numTransaction=${numTransaction}`, {
            observe: 'response',
            responseType: 'blob'
        });
    }

    annulationTransac(data) {
        return this.http.put<any[]>(`${environment.apiReseauUrl}/transactions/annulation`, data);
    }

    annulationTransacW(data) {
        return this.http.post<any[]>(`${environment.walletApi}/wallet/callback`, data);
    }

    recuTransac(recu) {
        return this.http.get(`${recu}`, {observe: 'response', responseType: 'blob'});
    }

    exportBrouillard(type) {
        return this.http.get(`${environment.apiReseauUrl}/transactions/brouillard/export/${type}`, {
            observe: 'response',
            responseType: 'blob'
        });
    }
    exportSituationCaisse(type) {
        return this.http.get(`${environment.apiReseauUrl}/transactions/situation_caisse/export/${type}`, {
            observe: 'response',
            responseType: 'blob'
        });
    }

    getBrouillard() {
        return this.http.get<any[]>(`${environment.apiReseauUrl}/transactions/brouillard/header`);
    }
    getSituationCaisse(query) {
        return this.http.get<any[]>(`${environment.apiReseauUrl}/transactions/situation_caisse/header?true=true${query}`);
    }

    brouillardPdf(query) {
        return this.http.get(`${environment.apiReseauUrl}/transactions/entite/jour/pdf?true=true${query}`, {
            observe: 'response',
            responseType: 'blob'
        });
    }

    brouillardCSV(query) {
        return this.http.get(`${environment.apiReseauUrl}/transactions/entite/jour/csv?true=true${query}`, {
            observe: 'response',
            responseType: 'blob'
        });
    }
    getTypeticket() {
        return this.http.get<any[]>(`${environment.walletApi}/type_tickets`);
    }
    venteTicket(ticket) {
        return this.http.post<any[]>(`${environment.walletApi}/vente_ticket/caissier`,ticket);
    }
    gethistTicket() {
        return this.http.get<any[]>(`${environment.walletApi}/vente_tickets`);
    }
    printTicket(id) {
        return this.http.get(`${environment.walletApi}/vente_ticket/pdf/${id}`, {
            observe: 'response',
            responseType: 'blob'
        });
    }
}

