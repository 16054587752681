// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
const BASE_API = 'https://proxy.pdg.sn';
const DIGI_MP_API = 'https://proxy.pdg.sn/api';
export const environment ={
  production: false,
  name: 'bgcloud-prod',
  mauritanieApi : BASE_API + '/mauritanie_services/api',
  apiBourseE: DIGI_MP_API,
  apiPas: DIGI_MP_API,
  apiDgse: DIGI_MP_API,
  urlFront:'https://caisse.nt-albey.ne/#/sessions/signin',
  apiAuthUrl: BASE_API+'/auth/api',
  apiAdminUrl: BASE_API+'/admin/api',
  apiReseauUrl: BASE_API+'/reseau/api',
  apiTransfertUrl: BASE_API+'/transfert/api',
  walletApi: BASE_API+'/wallet/api',
  apiDgpsn: BASE_API+'/dgpsn/api' ,
  apiAssurance: BASE_API+'/insurance/api',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
