import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { User } from '../models/user';
import { BehaviorSubject } from 'rxjs';
import { Caisse } from '../models/caisse';
import { TransactionsService } from './transactions.service';
import { Constantes } from '../constantes';
import * as $ from "jquery";
@Injectable({
  providedIn: 'root'
})
export class EntitesService {
  currentUser:User;
  private recentTransactionsSource = new BehaviorSubject([]);
  private caisseSource = new BehaviorSubject(new Caisse({}));
  private serviceSource = new BehaviorSubject(null); 
  currentCaisse = this.caisseSource.asObservable();
  currentService = this.serviceSource.asObservable();
  recentTransactions = this.recentTransactionsSource.asObservable();

  constructor( private http: HttpClient, 
    private _authService:AuthService,
    private _transactionsService:TransactionsService) {
    this.currentUser = this._authService.getUser();
   
  }
  
  //Met à jour la variable caisse
  changeCaisse(caisse: Caisse) {
    this.caisseSource.next(caisse);
  }

  changeService(service) {
    this.serviceSource.next(service);
  }

  getRecentTransactions() {
    this._transactionsService.getRecentTransactions(this.currentUser.caisse)
    .subscribe(res => {
       this.recentTransactionsSource.next(res[Constantes.HYDRA_MEMBER]);
    }) 
  }

  addLastTransaction(transaction) {
    let transactions = this.recentTransactionsSource.value;
    const lastIndex = transactions.length - 1;
    if( lastIndex == 9 ) {
      transactions.splice(lastIndex,1);
      transactions.unshift(transaction);
    }
    this.serviceSource.next(transactions);
  }

  
  
  
  updateSoldeRetrai(montantEnvoi) {
    let {value} = this.caisseSource;
    value.solde += montantEnvoi;
    this.changeCaisse(value);
  }
  
  abonnements() {
    return this.http.get<any>(`${environment.apiAdminUrl}/entities/mesabonnements`)
  }
  getMesServices(){
    return this.http.get<any>(`${environment.apiAdminUrl}/entities/messervices`)
  }
  getServicesById(id){
    return this.http.get<any>(`${environment.apiAdminUrl}/services/${id}`)
  }

  operateurs() {
   return this.http.get<any>(`${environment.apiAdminUrl}/operateurs?codeEntite=${this.currentUser.codeClient}`)
  }

  getOperateur(id) {
    return this.http.get<any>(`${environment.apiAdminUrl}/operateurs/${id}`)
  }

  caisseUser(data) {
   
    return this.http.get<any>(`${environment.apiReseauUrl}/caisses?pointDeVente=${data.agence}&code=${data.caisse}`)
  }

  getSoldeCaisse(){
    return this.http.get<any>(`${environment.apiReseauUrl}/caisse/solde`);
  }
  support(data){
    return this.http.post<any>(`${environment.walletApi}/osticket`,data);
  }
  goService(){
    try {
      $('#setserviceRef').click();
    } catch (e) {
    }
  }
  getFacturewallet(idtransaction){
    return this.http.get(`${environment.walletApi}/transactions/caisses/pdf?id=${idtransaction}`, {
      observe: 'response',
      responseType: 'blob'
    });
  }


}
